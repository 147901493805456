/* eslint-disable turbo/no-undeclared-env-vars */
'use client';

import { WebStorageStateStore } from 'oidc-client-ts';
import { FC, useEffect, useState } from 'react';
import { AuthProvider } from 'react-oidc-context';
import { Log } from 'oidc-client-ts';
import { useSearchParams } from 'next/navigation';
import { logger } from '../logger';

interface ClientAuthProviderProps {
  children: React.ReactNode;
  oidcConfig: {
    authority: string;
    client_id: string;
    redirect_uri: string;
  };
  orgId?: string;
  orgDomain?: string;
}

export const ZITADEL_DEFAULT_SCOPES = [
  'openid',
  'email',
  'profile',
  'offline_access',
  'urn:zitadel:iam:user:metadata',
  'urn:zitadel:iam:user:resourceowner',
  'urn:zitadel:iam:org:projects:roles',
];

const oidcClientLogger = logger.child({ package: 'oidc-client' });
Log.setLogger({
  debug(message) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    oidcClientLogger.debug(message as any);
  },
  info(message) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    oidcClientLogger.info(message as any);
  },
  warn(message) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    oidcClientLogger.warn(message as any);
  },
  error(message) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    oidcClientLogger.error(message as any);
  },
});
Log.setLevel(process.env.production ? Log.INFO : Log.DEBUG);

export const ClientAuthProvider: FC<ClientAuthProviderProps> = ({
  children,
  oidcConfig,
  orgId,
  orgDomain,
}) => {
  const [isClient, setIsClient] = useState(false);

  // The login flow can be scoped to a specific orgid and domain via query params.
  // note that they overrule the prop values for orgId and orgDomain
  const searchParams = useSearchParams();
  const orgIdQueryParam = searchParams.get('orgid') || null;
  const orgDomainQueryParam = searchParams.get('domain') || null;

  useEffect(() => setIsClient(true), []);

  const dynamicScopes = [];
  if ((orgId && orgId !== '') || orgIdQueryParam)
    dynamicScopes.push(`urn:zitadel:iam:org:id:${orgIdQueryParam ?? orgId}`);
  if ((orgDomain && orgDomain !== '') || orgDomainQueryParam)
    dynamicScopes.push(`urn:zitadel:iam:org:domain:primary:${orgDomainQueryParam ?? orgDomain}`);

  const scopes = [...ZITADEL_DEFAULT_SCOPES, ...dynamicScopes];

  useEffect(() => {
    if (window.location.search.includes('prompt') && window.location.pathname.includes('/chat')) {
      const query = window.location.search;
      sessionStorage.setItem('lastVisited', window.location.pathname + query);
    }
  }, []);

  return (
    <>
      {isClient && (
        <AuthProvider
          {...oidcConfig}
          scope={scopes.join(' ')}
          userStore={new WebStorageStateStore({ store: window.localStorage })}
          onSigninCallback={() => {
            sessionStorage.removeItem('hasAnErrorOccured');
            if (sessionStorage.getItem('lastVisited')) {
              const lastVisited = sessionStorage.getItem('lastVisited');
              window.location.href = lastVisited;
              return;
            }
            window.history.replaceState({}, document.title, window.location.pathname);
          }}
          automaticSilentRenew={false}
        >
          {children}
        </AuthProvider>
      )}
    </>
  );
};
