'use client';
import React from 'react';
import { Provider } from 'react-redux';
import { store } from '@/store/store';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from '@/store/store';

const ReduxProvider = ({ children }) => <>
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            {children}
        </PersistGate>
    </Provider>
</>;

export default ReduxProvider;
