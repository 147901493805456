import { chatSlice, translationSlice, preferencesSlice, dueDiligenceSlice } from '@/store';
import { configureStore, combineReducers } from '@reduxjs/toolkit';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['preferences'], // Only persist user preferences
};

const rootReducer = combineReducers({
  chat: chatSlice.reducer,
  translation: translationSlice.reducer,
  dueDiligence: dueDiligenceSlice.reducer,
  preferences: preferencesSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
